.cardBody {
	flex: 1;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.subtitle {
	flex: 1;
	font-size: 16px;
	margin-bottom: 4px;
}
.date,
.tags,
.eventLink {
	font-size: 14px;
}

.btns .btn {
	margin: 2px;
	width: 100%;
}

.statuses {
	display: flex;
	padding: 10px 0;
	flex-wrap: wrap;
	/* flex-direction: column; */
}

.statuses .status {
	border: 1px solid #08090a;
	border-radius: 4px;
	margin: 2px;
	padding: 2px 4px;
	cursor: pointer;
}

.statuses .status.active {
	background-color: #08090a;
	color: #f6f6f6;
}
