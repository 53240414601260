* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: 'Montserrat', sans-serif !important;
	background: #f6f6f6;
	overflow-x: hidden !important;
}
.nav.nav-tabs {
	border-bottom: 0 !important;
	display: flex !important;
	justify-content: flex-start !important;
	align-items: center !important;
	margin-bottom: 12px !important;
}
.nav-item.nav-link {
	border: 2px solid #5865f221 !important;
	border-radius: 4px !important;
	color: #5865f2 !important;
	margin-right: 8px !important;
	margin-bottom: 8px !important;
	padding: 8px 16px !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	transition: all 0.3s ease !important;
	display: inline-block !important;
	width: fit-content !important;
}
.nav-item.nav-link.active {
	border: 2px solid #5865f2 !important;
	color: #f6f6f6 !important;
	background: #5865f2 !important;
}
.form-control.disabled {
	background: #e6e6e6 !important;
}
::-webkit-scrollbar {
	width: 0px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
