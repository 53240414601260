.cardBody {
	flex: 1;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.department {
	font-size: 18px;
	font-weight: 600;
}
.quote {
	font-size: 14px;
}
.links {
	margin-top: 8px;
}

.link {
	font-size: 20px;
	margin-right: 8px;
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
