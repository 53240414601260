.cardBody {
	flex: 1;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.subtitle {
	flex: 1;
	font-size: 16px;
	margin-bottom: 4px;
}
.date,
.tags,
.videoLink {
	font-size: 14px;
}

.btns .btn {
	margin: 2px;
	width: 100%;
}
